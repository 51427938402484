import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { VerticalBarWrapper } from '../atoms/VerticalBarWrapper';

const verticalBarProps = {
  default: {
    borderWidth: 1,
    borderColor: '#000',
    padding: 'l'
  },
  'yellow-bold': {
    borderWidth: 8,
    borderColor: '#fc0',
    padding: 's'
  }
};

export const SectionHeader = ({ title, type, color }) => {
  const styles = {
    sectionHeaderDefault: css`
      ${theme.font.family('regularWeb')};
      font-weight: ${theme.font.weight('base')};
      font-size: clamp(1.375em, 2vw + 1rem, 2rem);
      letter-spacing: ${theme.letterSpacing('base')};
      line-height: ${theme.line.height('base')};
      color: ${color};
      text-transform: uppercase;
    `,
    sectionHeaderYellowBold: css`
      ${theme.font.family('boldWeb')};
      font-weight: ${theme.font.weight('base')};
      font-size: clamp(1.375em, 2vw + 1rem, 2rem);
      letter-spacing: ${theme.letterSpacing('base')};
      line-height: ${theme.line.height('base')};
      color: ${color};
      text-transform: uppercase;
    `
  };

  return (
    <VerticalBarWrapper {...verticalBarProps[type]}>
      <h3
        css={
          type === 'default'
            ? styles.sectionHeaderDefault
            : styles.sectionHeaderYellowBold
        }
      >
        {title}
      </h3>
    </VerticalBarWrapper>
  );
};

SectionHeader.defaultProps = {
  type: 'default',
  color: 'black'
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['default', 'yellow-bold']),
  color: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { t } from '../../../domain/services/configService';
import { NotFound } from '../../NotFound';

const styles = {
  title: css`
    font-size: 2.5rem;
    margin: 10px auto;
    letter-spacing: 2.5px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
  `,

  noResults: css`
    color: #4f4d4e;
    font-size: 1.25rem;
    letter-spacing: -0.01em;
    span {
      color: #fc0;
    }
  `
};

export const SearchNotFound = ({ query }) => (
  <NotFound>
    <h3 css={styles.title}>{t('we are sorry!')}</h3>
    <p css={styles.noResults}>
      {`${t('no results for')} `}
      <span>{query}</span>
    </p>
  </NotFound>
);

SearchNotFound.propTypes = {
  query: PropTypes.string.isRequired
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { queryService } from '../../../domain/services/queryService';
import { theme } from '../../../domain/theme';
import { Select } from '../atoms/Select';

const styles = {
  container: css`
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid #000;
    ${theme.mq.tablet} {
      padding: 10px;
      border-bottom: none;
    }
  `,

  label: css`
    display: none;
    ${theme.mq.tablet} {
      display: initial;
    }
  `,

  selectContainer: css`
    width: 100%;
    ${theme.mq.tablet} {
      width: auto;
    }
  `,

  select: css`
    padding: 5px 40px 5px 20px;
    margin: 10px 0;
    width: 200px;
    ${theme.mq.tablet} {
      padding: 5px 40px;
      width: auto;
      border-left: 1px solid #000;
    }
  `
};

export const SearchOption = ({
  name,
  defaultValue,
  label,
  selectOptions,
  onChange
}) => {
  const [value, setValue] = useState(
    queryService.getParameter(name) || defaultValue
  );
  const handleChange = event => {
    const urlSearchParams = new URLSearchParams(document.location.search);
    if (event.target.value === defaultValue) {
      urlSearchParams.delete(name);
    } else {
      urlSearchParams.set(name, event.target.value);
    }
    const url = `${window.location.pathname}?${urlSearchParams.toString()}`;
    window.history.replaceState({}, null, url);

    setValue(event.target.value);
    // call parent
    onChange(event.target.value);
  };

  return (
    <Select
      id={`search-${name}`}
      name={name}
      value={value}
      label={label}
      options={selectOptions}
      onChange={handleChange}
      customContainerStyles={styles.container}
      customLabelStyles={styles.label}
      customSelectContainerStyles={styles.selectContainer}
      customSelectStyles={styles.select}
    />
  );
};

SearchOption.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selectOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../domain/theme';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from;
  const myrange = [];

  while (i <= to) {
    myrange.push(i);
    i += step;
  }

  return myrange;
};

const navPagination = css`
  nav& {
    display: flex;
    justify-content: center;
    clear: both;
  }
`;

const pagination = css`
  ul& {
    display: block;
    margin: 40px auto 60px;
  }

  ul& li.page-item {
    display: inline-block;
  }

  ul& button.page-link {
    text-align: center;
    box-shadow: none !important;
    ${theme.font.family('regularWeb')};
    font-weight: 400;
    letter-spacing: 2.5px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    background: #fff;
    color: #939393;
    text-transform: uppercase;
    border-radius: 0;
    border: none;
    margin: 0 5px;
    display: block;
    padding: 7px 3px 6px 6px;
  }

  ul& button.page-link:focus {
    outline: 0;
  }

  ul& li.page-item.active button.page-link {
    color: #000;
    font-weight: 700;
    border: 3px solid #fc0;
  }

  ul& button.page-link:hover {
    color: #000;
  }
`;
class Pagination extends Component {
  constructor(props) {
    super(props);
    const { pageLimit = 30, pageNeighbours = 0 } = props;

    this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;

    // pageNeighbours can be: 0, 1 or 2
    this.pageNeighbours =
      typeof pageNeighbours === 'number'
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;
    this.state = { currentPage: props.currentPage };
  }

  componentDidMount() {
    // Avoid triggering a callback when Pagination is mounted
    this.gotoPage(this.props.currentPage, false);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.currentPage !== nextProps.currentPage) {
      return true;
    }
    if (this.props.totalPages !== nextProps.totalPages) {
      return true;
    }
    return false;
  }

  handleClick(page, evt) {
    evt.preventDefault();
    this.gotoPage(page);
  }

  handleMoveLeft(evt) {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - 1);
  }

  handleMoveRight(evt) {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + 1);
  }

  gotoPage(page, executeOnPageChangedCallback = true) {
    const { onPageChanged = f => f, totalPages } = this.props;

    const currentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData = {
      currentPage,
      totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords
    };

    this.setState(
      { currentPage },
      () =>
        executeOnPageChangedCallback &&
        onPageChanged(paginationData.currentPage)
    );
  }

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  fetchPageNumbers() {
    const { pageNeighbours } = this;
    const { totalPages, currentPage } = this.props;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = ['FIRST', LEFT_PAGE, ...extraPages, ...pages, totalPages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [1, ...pages, ...extraPages, RIGHT_PAGE, 'LAST'];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = ['FIRST', LEFT_PAGE, ...pages, RIGHT_PAGE, 'LAST'];
          break;
        }
      }

      return pages;
    }

    return range(1, totalPages);
  }

  render() {
    const { totalPages, currentPage } = this.props;
    if (totalPages < 2) return null;

    const pages = this.fetchPageNumbers();

    return (
      <nav aria-label="Search Pagination" css={navPagination}>
        <ul css={pagination}>
          {pages.map(page => {
            if (page === LEFT_PAGE) {
              return (
                <li key="Previous" className="page-item">
                  <button
                    className="page-link"
                    type="button"
                    aria-label="Previous"
                    onClick={e => this.handleMoveLeft(e)}
                  >
                    <span>&lt;</span>
                  </button>
                </li>
              );
            }
            if (page === RIGHT_PAGE) {
              return (
                <li key="Next" className="page-item">
                  <button
                    type="button"
                    className="page-link"
                    aria-label="Next"
                    onClick={e => this.handleMoveRight(e)}
                  >
                    <span>&gt;</span>
                  </button>
                </li>
              );
            }
            if (page === 'FIRST') {
              return (
                <li key="first" className="page-item">
                  <button
                    className="page-link"
                    type="button"
                    aria-label="First"
                    onClick={e => this.handleClick(1, e)}
                  >
                    <span>&laquo;</span>
                  </button>
                </li>
              );
            }
            if (page === 'LAST') {
              return (
                <li key="first" className="page-item">
                  <button
                    className="page-link"
                    type="button"
                    aria-label="Last"
                    onClick={e => this.handleClick(totalPages, e)}
                  >
                    <span>&raquo;</span>
                  </button>
                </li>
              );
            }
            return (
              <li
                key={page}
                className={`page-item${currentPage === page ? ' active' : ''}`}
              >
                <button
                  type="button"
                  className="page-link"
                  onClick={e => this.handleClick(page, e)}
                >
                  {page}
                </button>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
  currentPage: PropTypes.number
};

Pagination.defaultProps = {
  pageLimit: 20,
  pageNeighbours: 2,
  onPageChanged: undefined,
  currentPage: 1
};

export default Pagination;

import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { IconSearch } from '../../Icon/IconSearch';

export const IconWrapper = ({
  icon,
  size,
  underlined,
  color,
  backgroundColor,
  border,
  borderColor,
  children
}) => {
  const styles = {
    container: css`
      border-bottom: ${underlined ? `2px solid ${borderColor};` : 'none'};
      ${border && `border: 1px solid ${borderColor};`}
      display: flex;
      align-items: center;
      padding: 10px;
      background: ${backgroundColor};
    `,

    icon: css`
      margin: 0 16px 0 8px;
    `
  };

  // At this moment, this IconWrapper is used only by search
  const IconComponent = icon === 'search' ? IconSearch : null;

  return (
    <div css={styles.container}>
      {IconComponent && (
        <span css={styles.icon}>
          <IconComponent
            size={size === 'large' ? [24, 36, 48] : 24}
            color={color}
            display="block"
          />
        </span>
      )}
      {children}
    </div>
  );
};

IconWrapper.defaultProps = {
  size: 'small',
  icon: undefined,
  underlined: false,
  color: '#000',
  backgroundColor: '#fff',
  border: false,
  borderColor: '#000'
};

IconWrapper.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  icon: PropTypes.string,
  underlined: PropTypes.bool,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.bool,
  borderColor: PropTypes.string,
  children: PropTypes.node.isRequired
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { conf, t } from '../../../domain/services/configService';
import { theme } from '../../../domain/theme';
import { SearchOption } from '../molecules/SearchOption';
import { Button } from '../atoms/Button';
import { IconFilter } from '../../Icon/IconFilter';
import { defaultSearchValues } from './SearchForm/defaultSearchValues';

export const SearchOptions = ({ onTypeChange, onSortChange }) => {
  const [showOptionsInMobile, setShowOptionsInMobile] = useState(false);

  const styles = {
    filter: css`
      display: flex;
    `,

    button: css`
      ${theme.mq.tablet} {
        display: none;
      }
    `,

    filterText: css`
      letter-spacing: 3px;
      margin-left: 10px;
    `,

    container: css`
      ${theme.mq.tablet} {
        display: flex;
      }
    `,

    option: css`
      display: ${showOptionsInMobile ? 'block' : 'none'};
      ${theme.mq.tablet} {
        width: 50%;
        display: block;
      }
    `
  };

  const handleClick = () => setShowOptionsInMobile(!showOptionsInMobile);
  const countryCode = conf.siteCountryCode.toLowerCase();
  let selectOptions = [
    { name: t('Filter by'), value: '', disabled: true },
    { name: t('__ALL__'), value: 'all' },
    { name: t('Article'), value: 'article' },
    { name: t('Video'), value: 'video' },
    { name: t('Photography'), value: 'photo_gallery' },
    { name: t('Show'), value: 'show' },
    { name: t('Slideshow'), value: 'vertical_slideshow_gallery' },
    { name: t('Author'), value: 'author' },
    { name: t('Photographer'), value: 'photographer' }
  ];
  if (countryCode === 'it') {
    selectOptions = selectOptions.filter(option => option.value !== 'show');
  }
  return (
    <div css={styles.container}>
      <div css={styles.button}>
        <Button onClick={handleClick} fullWidth padding="xs" color="lightGray">
          <div css={styles.filter}>
            <IconFilter size={16} />
            <span css={styles.filterText}>{t('Filter')}</span>
          </div>
        </Button>
      </div>

      <div css={styles.option}>
        <SearchOption
          name="type"
          defaultValue={defaultSearchValues.type}
          label={`${t('Filter by')}:`}
          selectOptions={selectOptions}
          onChange={onTypeChange}
        />
      </div>

      <div css={styles.option}>
        <SearchOption
          name="sort"
          defaultValue={defaultSearchValues.sort}
          label={`${t('__SORT_BY__')}:`}
          selectOptions={[
            { name: t('__SORT_BY__'), value: '', disabled: true },
            { name: t('Relevance'), value: 'relevance' },
            { name: t('Date'), value: 'date' }
          ]}
          onChange={onSortChange}
        />
      </div>
    </div>
  );
};

SearchOptions.propTypes = {
  onTypeChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../../domain/theme';
import { GridRow, GridColumn } from '../atoms/Grid';
import { SectionHeader } from '../molecules/SectionHeader';

const smallDesktopWidth = `300px + ( ${theme.grid.gutter('base')} * 2 )`;
const largeDesktopWidth = `300px + ( ${theme.grid.gutter('l')} * 2 )`;

export const GridLeftRight = ({ header, left, right }) => (
  <>
    {header && <SectionHeader title={header} />}
    <GridRow gutter={[null, null, null, 'l']}>
      <GridColumn
        width={[
          12,
          null,
          `calc(100% - ( ${smallDesktopWidth} ))`,
          `calc(100% - ( ${largeDesktopWidth} ))`
        ]}
      >
        {left}
      </GridColumn>
      <GridColumn
        width={[
          0,
          null,
          `calc(${smallDesktopWidth})`,
          `calc(${largeDesktopWidth})`
        ]}
      >
        {right}
      </GridColumn>
    </GridRow>
  </>
);

GridLeftRight.defaultProps = {
  header: undefined
};

GridLeftRight.propTypes = {
  header: PropTypes.string,
  left: PropTypes.arrayOf(PropTypes.node).isRequired,
  right: PropTypes.arrayOf(PropTypes.node).isRequired
};

import React from 'react';
import { WithIcon } from './WithIcon';

export const IconFilter = WithIcon(
  'Filter',
  '0 0 1024 1024',
  <path
    transform="scale(1, -1) translate(0, -960)"
    d="M4 784h1016v-60h-1016v60zM4 478h1016v-60h-1016v60zM4 172h1016v-60h-1016v60zM164 854h60v-200h-60v200zM482 548h60v-200h-60v200zM800 242h60v-200h-60v200z"
  />
);

import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';

export const VerticalBarWrapper = ({
  children,
  borderWidth,
  borderColor,
  padding
}) => {
  const styles = {
    main: css`
      border-left: ${borderWidth}px solid ${borderColor};
      padding-left: ${theme.spacing(padding)};
    `
  };

  return <div css={styles.main}>{children}</div>;
};

VerticalBarWrapper.defaultProps = {
  borderWidth: 1,
  borderColor: '#000',
  padding: 'l'
};

VerticalBarWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  borderWidth: PropTypes.number,
  borderColor: PropTypes.string,
  padding: PropTypes.string
};

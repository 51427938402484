import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { CardItemHorizontalLegacy } from './CardItemHorizontalLegacy';

const imageMaxWidths = {
  tablet: 344,
  small_desktop: 350,
  large_desktop: 555
};

export const SearchResult = ({ data, page, query }) => {
  const gtmClickEvent = {
    event: 'Click Tracking from Search',
    search_pagination: page,
    location: data.path,
    search_keyword: query
  };

  return (
    <div
      css={css`
        margin-bottom: 45px;
      `}
    >
      <CardItemHorizontalLegacy
        data={data}
        imageMaxWidths={imageMaxWidths}
        gtmClickEvent={gtmClickEvent}
      />
    </div>
  );
};

SearchResult.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  page: PropTypes.number.isRequired,
  query: PropTypes.string.isRequired
};

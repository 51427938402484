import React, { useState, useEffect } from 'react';
import { conf } from '../../../../domain/services/configService';
import { apiClientService } from '../../../../domain/services/apiClientService';
import { queryService } from '../../../../domain/services/queryService';
import { defaultSearchValues } from './defaultSearchValues';
import { ContainerMain } from '../../atoms/ContainerMain';
import { SearchText } from '../SearchText';
import { SearchOptions } from '../SearchOptions';
import { SearchResults } from '../SearchResults';
import Pagination from '../../../Pagination';

const itemsPerPage = 20;

const setPageQuery = page => {
  const urlSearchParams = new URLSearchParams(document.location.search);
  if (page === defaultSearchValues.page) {
    urlSearchParams.delete('page');
  } else {
    urlSearchParams.set('page', page);
  }
  const url = `${window.location.pathname}?${urlSearchParams.toString()}`;
  window.history.replaceState({}, null, url);
};

export const SearchForm = () => {
  // Define all states that affect search and read
  // them from URL, or get their default values.
  const [search, setSearch] = useState({
    initialText: (
      queryService.getParameter('q') || defaultSearchValues.query
    ).toLowerCase(),
    query: (
      queryService.getParameter('q') || defaultSearchValues.query
    ).toLowerCase(),
    type: queryService.getParameter('type') || defaultSearchValues.type,
    sort: queryService.getParameter('sort') || defaultSearchValues.sort,
    page:
      parseInt(queryService.getParameter('page'), 10) ||
      defaultSearchValues.page
  });

  const setInitialTextAndQuery = initialText => {
    // When initial text changes (triggered by ExpandedSearch), page is reset to 1.
    setPageQuery(1);
    setSearch({ ...search, query: initialText, initialText, page: 1 });
  };

  const setQuery = query => {
    // When search changes, page is reset to 1.
    setPageQuery(1);
    setSearch({ ...search, query, page: 1 });
  };

  const setType = type => {
    // When type changes, page is reset to 1.
    setPageQuery(1);
    setSearch({ ...search, type, page: 1 });
  };

  const setSort = sort => {
    // When sort changes, page is reset to 1.
    setPageQuery(1);
    setSearch({ ...search, sort, page: 1 });
  };

  const setPage = page => {
    if (page) {
      window.scrollTo(0, 0);
      setPageQuery(page);
      setSearch({ ...search, page });
    }
  };

  // Ensure that searching from ExpandedSearch triggers a new search
  useEffect(() => {
    const currentQuery = (
      queryService.getParameter('q') || defaultSearchValues.query
    ).toLowerCase();
    if (currentQuery !== search.query) {
      setInitialTextAndQuery(currentQuery);
    }
  });

  const [results, setResults] = useState({ executed: false, items: [] });
  useEffect(() => {
    const fetchData = async words => {
      const { response } = await apiClientService.getJson(
        `${conf.searchEndpoint}?q=${words}`
        // `https://pn8duyuo2j.execute-api.eu-central-1.amazonaws.com/default/PLUTO_Search_ST_UK?q=${words}`
      );
      setResults({
        executed: true,
        items: response && response.results ? response.results : []
      });
    };
    if (search.query.length > 1) {
      fetchData(search.query);
    }
  }, [search.query]);

  // Filter by type
  let finalResults;
  if (results.executed) {
    let filteredResults = [...results.items];
    if (search.type !== defaultSearchValues.type) {
      filteredResults = results.items.filter(
        result => result.type === search.type
      );
    }

    // Sort
    let filteredAndSortedResults = filteredResults;
    if (search.sort !== defaultSearchValues.sort) {
      filteredAndSortedResults = filteredResults.sort(
        (a, b) => b.changed - a.changed
      );
    }

    // Trim results to 100
    finalResults = filteredAndSortedResults.slice(0, 100);
  }

  const paginationResultsFrom = (search.page - 1) * itemsPerPage;
  const paginationResultsTo = paginationResultsFrom + itemsPerPage;
  const currentPaginationResults = finalResults
    ? finalResults.slice(paginationResultsFrom, paginationResultsTo)
    : [];

  const handleSubmit = event => {
    event.preventDefault();
  };

  return (
    <>
      <ContainerMain>
        <form onSubmit={handleSubmit}>
          <SearchText initialText={search.initialText} onChange={setQuery} />
          <SearchOptions onTypeChange={setType} onSortChange={setSort} />
        </form>
      </ContainerMain>

      {finalResults && (
        <SearchResults
          query={search.query}
          page={search.page}
          results={currentPaginationResults}
        />
      )}
      {finalResults && (
        <Pagination
          totalPages={Math.ceil(finalResults.length / itemsPerPage)}
          pageLimit={itemsPerPage}
          pageNeighbours={1}
          onPageChanged={setPage}
          currentPage={search.page}
        />
      )}
    </>
  );
};

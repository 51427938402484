import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { facePaint } from '../../../domain/theme/utils/facePaint';
import { theme } from '../../../domain/theme';
import { stringOrArrayPlusNull } from '../../propTypes';

const colors = {
  // link color, background color and border color
  primary: { default: ['#333', '#fc0', null], hover: ['#000', '#fc0', null] },
  darkGray: { default: ['#fff', '#333', null], hover: ['#fc0', '#333', null] },
  lightGray: { default: ['#333', '#eee', null], hover: ['#000', '#eee', null] },
  white: { default: ['#333', '#fff', '#000'], hover: ['#fff', '#000', '#000'] },
  black: { default: ['#fff', '#000', '#fff'], hover: ['#000', '#fff', '#fff'] }
};

export const Button = props => {
  const { to, type, color, padding, icon, fullWidth, onClick, children } =
    props;
  const colorKey = color || 'primary';
  const [linkColor, backgroundColor, borderColor] = colors[colorKey].default;
  const [linkHoverColor, backgroundHoverColor, borderHoverColor] =
    colors[colorKey].hover;

  const styles = {
    main: css`
      display: ${fullWidth ? 'block' : 'inline-block'};
      width: ${fullWidth ? '100%' : 'auto'};
      ${facePaint({
        padding:
          padding.indexOf(' ') !== -1
            ? theme.spacing.parse(padding)
            : theme.spacing(padding)
      })};
      margin: 0 auto;
      color: ${linkColor};
      background-color: ${backgroundColor};
      border: ${borderColor ? `1px solid ${borderColor}` : 0};
      &:hover {
        color: ${linkHoverColor};
        background-color: ${backgroundHoverColor};
        border: ${borderHoverColor ? `1px solid ${borderHoverColor}` : 0};
      }
      ${theme.font.family('boldWeb')};
      letter-spacing: ${theme.letterSpacing('base')};
      outline: none;
    `,
    icon: css`
      margin-right: ${theme.spacing('xs')};
      float: left;
    `
  };

  if (to) {
    // Create a clean props to be rendered with "a".
    const disallowed = [
      'to',
      'type',
      'secondary',
      'fullWidth',
      'children',
      'css',
      'color',
      'padding'
    ];
    const tagProps = Object.fromEntries(
      Object.entries(props).filter(([key]) => !disallowed.includes(key))
    );

    return (
      <a href={to} css={styles.main} {...tagProps}>
        {icon && <div css={styles.icon}>{icon}</div>}
        {children}
      </a>
    );
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} onClick={onClick} css={styles.main}>
      {icon && <div css={styles.icon}>{icon}</div>}
      {children}
    </button>
  );
};

Button.defaultProps = {
  to: undefined,
  type: 'button',
  color: 'primary',
  padding: 's',
  icon: undefined,
  fullWidth: false,
  onClick: undefined
};

Button.propTypes = {
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  color: PropTypes.oneOf([
    'primary',
    'darkGray',
    'lightGray',
    'black',
    'white'
  ]),
  padding: stringOrArrayPlusNull,
  icon: PropTypes.node,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
};

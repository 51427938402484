import React from 'react';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { SearchForm } from '../organisms/SearchForm';

// Since this is a template, we can customize its spacings.
// Note that this strategy must be confirmed true when
// developing other pieces of atomic design, so it could change.
const styles = {
  main: css`
    margin-top: ${theme.spacing('xl')};
  `
};

export const SearchTemplate = () => (
  <div css={styles.main}>
    <SearchForm />
  </div>
);

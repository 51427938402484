import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpointService } from '../../../domain/services/breakpointService';
import { SearchNotFound } from '../molecules/SearchNotFound';
import { SearchResult } from './SearchResult';
import { Ad } from '../atoms/Ad';
import { GridLeftRight } from './GridLeftRight';
import { ContainerMain } from '../atoms/ContainerMain';

const adElem = (
  <div
    css={css`
      margin: 30px auto 5px;
    `}
  >
    <Ad id="search_grid__SearchResults" />
  </div>
);

export const SearchResults = ({ query, page, results }) => {
  const stickyAd = (
    <Ad
      key={`search_side__SearchResults__${page}`}
      id="search_side__SearchResults"
    />
  );

  const resultNodes = results.map((result, index) => {
    const adIndex = index - 1;
    return (
      <React.Fragment key={result.id}>
        {adIndex !== 0 &&
          adIndex % 4 === 0 &&
          breakpointService.isHandheld() &&
          adElem}
        <SearchResult data={result} page={page} query={query} />
      </React.Fragment>
    );
  });

  return results.length > 0 ? (
    <ContainerMain>
      <GridLeftRight left={resultNodes} right={[stickyAd]} />
    </ContainerMain>
  ) : (
    <SearchNotFound query={query} />
  );
};

SearchResults.propTypes = {
  query: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  results: PropTypes.arrayOf(PropTypes.any).isRequired
};
